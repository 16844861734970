import React, { useState, useMemo, useEffect } from 'react';
import AntDesignCalendar from '../../constant/antDesign/calendar';
import dayjs, { Dayjs } from 'dayjs';
import isMobile from '../../constant/isMobile';
import CalendarButton from '../../constant/antDesign/calendarButton';
import { User } from '../../../models/user';
import { WorkSchedules } from '../../../models/employee/workSchedule/workSchedule';
import useWorkSchedules from '../../../api/hooks/workSchedules/useWorkSchedules';

const WorkSchedulesIndex: React.FC = () => {
  const startDate = useMemo(() => dayjs().subtract(1, 'month').startOf('month'), []);
  const endDate = useMemo(() => dayjs().endOf('month'), []);
  const [limit, setLimit] = useState<number>(1131);

  const [value, setValue] = useState(() => dayjs('2017-01-25'));
  const [selectedValue, setSelectedValue] = useState(() => dayjs('2017-01-25'));
  const [data, setData] = useState<WorkSchedules | null>(null);
  const [show, setShow] = useState(false);
  const [showWSD, setWSDShow] = useState(false);

  const params = useMemo(() => ({
    'filter_between[]': [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
    limit,
  }), [startDate, endDate, limit]);

  const { workSchedules, loading } = useWorkSchedules(params);

  // Refactor dateCellRender logic into useEffect
  const [renderedCells, setRenderedCells] = useState<any>(null);

  useEffect(() => {
    const renderCells = (date: Dayjs) => {
      const schedulesForDate = workSchedules.filter(schedule =>
        dayjs(schedule.working_date).isSame(date, 'day')
      );

      return schedulesForDate.map(schedule => {
        const fullName = isMobile
          ? `${schedule.user?.first_name.charAt(0)}${schedule.user?.last_name.charAt(0)}`
          : `${schedule.user?.first_name} ${schedule.user?.last_name}`;

        return (
          <CalendarButton
            key={schedule.id}
            className="text-center d-block mb-1"
            type={'primary'}
            size={'small'}
            onClick={() => handleScheduleClick(schedule)}
          >
            {`${fullName}`}
          </CalendarButton>
        );
      });
    };

    setRenderedCells(() => renderCells);
  }, [workSchedules]);

  const handleScheduleClick = (schedule: WorkSchedules) => {
    setShow(false);
    setWSDShow(true);

    if (schedule.id !== undefined) {
      console.log(schedule);
      setShow(false);
      setData({
        ...new WorkSchedules(),
        user: schedule?.user || new User(),
        working_date: schedule.working_date as string,
      });
      setWSDShow(true);
    } else {
      console.error('Schedule ID is undefined');
    }
  };

  const onSelect = (newValue: Dayjs, selectInfo: any) => {
    if (selectInfo.source === 'date') {
      setValue(newValue);
      setSelectedValue(newValue);
      setShow(true);
    }
  };

  useEffect(() => {
    console.log('Fetching with params:', params);
  }, [params]);

  return (
    <>
      <AntDesignCalendar dateCellRender={renderedCells} onSelect={onSelect} />
    </>
  );
};
export default WorkSchedulesIndex;
