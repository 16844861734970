import { AttributeMeta } from "./attributeMeta";

export class Attribute {
    constructor(
        public id = 0,
        public name = '',
        public type = '',
        public ordernum = '',
        public color = '',
        public attributeMeta = new AttributeMeta(),
    ) {
    }
}