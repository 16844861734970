
import { User } from "../../user";

export class WorkSchedules {
    constructor(
        public id = 0,
        public user_id = '',
        public ip = '',
        public working_date = '',
        public definition = '',
        public user = new User()
    ) {
    }
}